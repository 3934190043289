import React from 'react'
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom'
import { useAuth } from '../hooks/auth'
import Signed from '../pages/_layouts/Signed'

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth()
  let PageRendered: JSX.Element
  // const PageRendered = <Component />
  if (isPrivate) {
    PageRendered = (
      <Signed>
        <Component />
      </Signed>
    )
  } else {
    PageRendered = (
      <>
        <Component />
      </>
    )
  }
  if (isPrivate === !!user) {
    return <ReactDOMRoute {...rest} render={() => PageRendered} />
  }
  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return (
          <Redirect
            to={{
              state: { from: location },
              pathname: isPrivate ? '/' : '/dashboard',
            }}
          />
        )
      }}
    />
  )

  return <ReactDOMRoute {...rest} render={() => PageRendered} />
}

export default Route

import {
  Button,
  Icon,
  IconButton,
  ButtonProps as ChakraButtonProps,
  ComponentWithAs,
} from '@chakra-ui/react'
import { IconType } from 'react-icons'

interface ResponsiveButtonProps extends ChakraButtonProps {
  isWideVersion: boolean
  text: string
  icon: IconType
  iconSize: number
}
type ResponsiveButtonFinal = ComponentWithAs<'button', ResponsiveButtonProps>

export const ResponsiveButton: ResponsiveButtonFinal = ({
  isWideVersion,
  text,
  icon,
  iconSize,
  size,
  fontSize,
  colorScheme,
  onClick,
  ...rest
}) => {
  if (!isWideVersion)
    return (
      <IconButton
        {...rest}
        aria-label={text}
        fontSize={fontSize}
        size={size}
        colorScheme={colorScheme}
        icon={<Icon as={icon} fontSize={iconSize} />}
        onClick={onClick}
      />
    )

  return (
    <Button
      {...rest}
      fontSize={fontSize}
      size={size}
      colorScheme={colorScheme}
      leftIcon={<Icon as={icon} fontSize={iconSize} />}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

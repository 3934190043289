/* eslint-disable prettier/prettier */
import {
  Button,
  Flex,
  HStack,
  useToast,
  VStack,
  Text,
  CheckboxGroup,
  Checkbox,
  FormControl,
  SimpleGrid,
  FormLabel,
  FormErrorMessage,
  Box,
  IconButton,
  Icon,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Link, useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useState, useEffect, useCallback } from 'react'
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri'
import { Input } from '../../components/Form/Input'
import api from '../../services/api'
import { isOfType } from '../../utils/isOfType'
import { AppError } from '../../config/AppErrorType'
import { ContainerBox } from '../../components/ContainerBox'
import { CreationPageHeader } from '../../components/CreationPageHeader'
import { Select } from '../../components/Form/Select'
import { Toast } from '../../components/Toast'


type ClientCategoryProps = {
  name: string
  id: string
}

type ClientsCreateFormData = {
  name: string
  managerId: string
  clientUser: {
    name: string
    email: string
  }
  clientCategories: string[]
  clientTeam: string[]
  plans: { name: string }[]
}

type UserProps = {
  id: string
  name: string
  email: string
  createdAt: Date
  avatar: string
}

type ClientProps = {
  id: string
  name: string
  createdAt: Date
}

const clientsCreateFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  managerId: yup.string().required('Gestor obrigatório'),
  clientUser: yup.object().shape({
    name: yup.string().required('Nome do usuário obrigatório'),
    email: yup
      .string()
      .required('E-mail do usuário obrigatório')
      .email('E-mail inválido'),
  }),
  clientCategories: yup
    .array()
    .of(yup.string())
    .typeError('Selecione ao menos uma categoria')
    .min(1, 'Selecione ao menos uma categoria'),

  clientTeam: yup
    .array()
    .of(yup.string())
    .typeError('Selecione ao menos uma pessoa para a equipe')
    .min(1, 'Selecione ao menos uma pessoa para a equipe'),
  plans: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Nome do plano é obrigatório'),
      isRequired: yup.boolean(),
    })
  ).typeError('Crie ao menos um plano para o cliente')
    .min(1, 'Crie ao menos um plano para o cliente'),
})

export default function ClientsCreate() {
  const borderColorBox = useColorModeValue('gray.200', 'gray.700')
  const history = useHistory()

  const toast = useToast()

  const { register, control, handleSubmit, formState, setValue, getValues, setFocus } =
    useForm<ClientsCreateFormData>({
      resolver: yupResolver(clientsCreateFormSchema),
    })
  const { errors } = formState

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'plans',
  })
  useEffect(() => {
    if (fields.length === 0) {
      // append({})
      setFocus("name");
    }
  }, [append, fields.length, setFocus])

  const [managerIdOld, setManagerIdOld] = useState('')

  const [errorCheckbox, setErrorCheckbox] = useState(null)
  const [errorCheckboxTeam, setErrorCheckboxTeam] = useState(null)
  const [errorCheckboxPlans, setErrorCheckboxPlans] = useState(null)
  useEffect(() => {
    console.log(errors)
    setErrorCheckbox((errors?.clientCategories as any)?.message)
    setErrorCheckboxTeam((errors?.clientTeam as any)?.message)
    setErrorCheckboxPlans((errors?.plans as any)?.message)
  }, [errors])
  useEffect(() => console.log(errorCheckboxPlans), [errorCheckboxPlans])

  const [clientCategories, setClientCategories] = useState<ClientCategoryProps[]>([])
  useEffect(() => {
    async function loadClientCategories() {
      const response = await api.get<{ categories: ClientCategoryProps[] }>(
        '/clients/categories',
      )
      const { categories } = response.data
      setClientCategories(categories)
    }
    loadClientCategories()
  }, [])

  const [users, setUsers] = useState<UserProps[]>([])

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get<{ users: UserProps[] }>('/users')
      const usersFromApi = response.data.users
      setUsers(usersFromApi)
    }
    loadUsers()
  }, [])

  const handleCreateClient: SubmitHandler<ClientsCreateFormData> = async data => {
    console.log(data)

    try {
      toast({
        render: () => (
          <Toast
            title="Creating new client"
            description="Novo cliente sendo criado..."
            status="info"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      // await new Promise(resolve => setTimeout(resolve, 1500))
      const response = await api.post('/clients', data)
      const { client: createdClient, clientPlans: createdPlans } = response.data

      console.log({ createdClient, createdPlans })
      toast({
        render: () => (
          <Toast
            title="Client created"
            description="Client created successfully"
            status="success"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })
      history.push('/clients')
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao criar cliente'
      toast({
        render: () => (
          <Toast
            title="Client creation error"
            description={errorMessage}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: false,
        position: 'bottom-left',
      })
    }
  }

  const handleChangeManager = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      console.log(e.target.value)
    },
    [],
  )

  return (
    <ContainerBox as="form" onSubmit={handleSubmit(handleCreateClient)}>
      <CreationPageHeader title="Criar cliente" />
      <VStack spacing="8">
        <Input error={errors.name} {...register('name')} label="Nome" />
        <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
          <Input
            error={errors.clientUser?.name}
            {...register('clientUser.name')}
            label="Nome do usuário principal"
          />
          <Input
            error={errors.clientUser?.email}
            {...register('clientUser.email')}
            type="email"
            label="E-mail do usuário principal"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
          <FormControl isInvalid={errors.clientCategories !== undefined}>
            <FormLabel htmlFor="isRequired"> Categorias do Cliente</FormLabel>
            <CheckboxGroup colorScheme="orange">
              <VStack align="left">
                {clientCategories.map((clientCategory, index) => (
                  <Checkbox
                    value={clientCategory.id}
                    key={clientCategory.id}
                    {...register('clientCategories')}
                  >
                    {clientCategory.name}
                  </Checkbox>
                ))}
              </VStack>
            </CheckboxGroup>
            {!!errors.clientCategories && (
              <FormErrorMessage>{errorCheckbox}</FormErrorMessage>
            )}
          </FormControl>
          <Select
            error={errors.managerId}
            {...register('managerId')}
            onChange={handleChangeManager}
            label="Gestor"
            placeholder="Selecione o gestor do cliente"
            options={users.map(user => ({ value: user.id, text: user.name }))}
          />
        </SimpleGrid>
        <FormControl isInvalid={errors.clientTeam !== undefined}>
          <FormLabel htmlFor="isRequired"> Equipe da Mirador</FormLabel>
          <CheckboxGroup colorScheme="orange">
            <SimpleGrid minChildWidth={['240px', '180px']} spacing={['4', '6']} w="100%">
              {users.map(user => (
                <Checkbox value={user.id} key={user.id} {...register('clientTeam')}>
                  {user.name}
                </Checkbox>
              ))}
            </SimpleGrid>
          </CheckboxGroup>
          {!!errors.clientTeam && (
            <FormErrorMessage>{errorCheckboxTeam}</FormErrorMessage>
          )}
        </FormControl>
        <Flex width="100%" direction="column">
          <Text width="100%" fontWeight="bold" fontSize="lg">Planos do cliente</Text>
          <FormControl
            isInvalid={errors.plans !== undefined && (errors.plans as any)?.type === 'min'}
          >
            {(errors.plans as any)?.type === 'min' && (
              <FormErrorMessage>{(errors.plans as any)?.message}</FormErrorMessage>
            )}
          </FormControl>
          <Stack spacing="2" mt="2">
            {fields.map(({ id }, index) => (
              <Box
                px="4"
                py="2"
                w="100%"
                borderColor={borderColorBox}
                borderWidth="1px"
                borderRadius="8px"
                key={id}
              >
                <Flex w="100%" align="flex-end" justify="space-between">
                  <Input
                    label="Nome do plano"
                    error={
                      errors.plans !== undefined ?
                        errors.plans[index]?.name : undefined
                    }
                    {...register(`plans.${index}.name`)}
                  />
                  <Box ml="3" pb="2">
                    <IconButton
                      size="sm"
                      disabled={fields.length === 1}
                      colorScheme="red"
                      variant="link"

                      icon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      aria-label="Deletar item"
                      onClick={() => remove(index)}
                    />
                  </Box>
                </Flex>
                <VStack spacing="6" w="100%" mt="2">

                  <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%" />
                </VStack>
              </Box>
            ))}
          </Stack>
        </Flex>
      </VStack>
      <Flex mt={['6', '8']} justify="space-between">
        <Button
          colorScheme="purple"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}
          onClick={() => append({})}
        >
          Novo plano
        </Button>
        <HStack spacing="4">
          <Button as={Link} to="/clients" colorScheme="gray">
            Cancelar
          </Button>
          <Button isLoading={formState.isSubmitting} colorScheme="orange" type="submit">
            Salvar
          </Button>
        </HStack>
      </Flex>
    </ContainerBox >
  )
}

import { Switch, useLocation } from 'react-router-dom'

import { useEffect } from 'react'
import Route from './Route'

import Recovery from '../pages/Recovery'
import SignIn from '../pages/SignIn'
import Dashboard from '../pages/Dashboard'
import Users from '../pages/Users'
import UsersCreate from '../pages/UsersCreate'
import { useSidebarDrawer } from '../contexts/SidebarDrawerContext'
import SectionModels from '../pages/SectionModels'
import SectionModelsCreate from '../pages/SectionModelsCreate'
import ClientsCreate from '../pages/ClientsCreate'
import Clients from '../pages/Clients'
import SectionModelsEdit from '../pages/SectionModelsEdit'
import ClientCategories from '../pages/ClientCategories'
import ClientCategoriesCreate from '../pages/ClientCategoriesCreate'
import ClientsDetail from '../pages/ClientsDetail'
import SolicitationsCreate from '../pages/SolicitationsCreate'

const Routes: React.FC = () => {
  const { onClose } = useSidebarDrawer()

  const location = useLocation()

  useEffect(() => onClose(), [location.pathname, onClose])

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/recovery" component={Recovery} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/users" exact component={Users} isPrivate />
      <Route path="/users/create" component={UsersCreate} isPrivate />

      <Route path="/clients" exact component={Clients} isPrivate />
      <Route path="/clients/create" exact component={ClientsCreate} isPrivate />

      <Route
        path="/clients/:clientId/details"
        exact
        component={ClientsDetail}
        isPrivate
      />
      <Route path="/clients/:clientId/edit" exact component={ClientsCreate} isPrivate />

      <Route path="/clients/categories" exact component={ClientCategories} isPrivate />
      <Route
        path="/clients/categories/create"
        exact
        component={ClientCategoriesCreate}
        isPrivate
      />

      <Route
        path="/solicitations/create/:clientId?"
        exact
        component={SolicitationsCreate}
        isPrivate
      />

      <Route
        path="/solicitations/section-models"
        exact
        component={SectionModels}
        isPrivate
      />
      <Route
        path="/solicitations/section-models/:sectionModelId"
        exact
        component={SectionModelsEdit}
        isPrivate
      />
      <Route
        path="/solicitations/section-models/create"
        exact
        component={SectionModelsCreate}
        isPrivate
      />
    </Switch>
  )
}

export default Routes

import { Flex, Icon, Input, useColorModeValue } from '@chakra-ui/react'
import { useRef } from 'react'
import { RiSearchLine } from 'react-icons/ri'

export function SearchBox() {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const bgFlex = useColorModeValue('gray.50', 'gray.800')
  const color = useColorModeValue('gray.600', 'gray.200')
  const colorPlaceholder = useColorModeValue('gray.400', 'gray.400')
  return (
    <Flex
      as="label"
      flex="1"
      py="4"
      px="8"
      ml="6"
      maxWidth={400}
      borderRadius="full"
      alignSelf="center"
      color={color}
      bg={bgFlex}
    >
      <Input
        color={color}
        variant="unstyled"
        px="4"
        mr="4"
        placeholder="Buscar na plataforma"
        _placeholder={{ color: colorPlaceholder }}
        ref={searchInputRef}
      />
      <Icon as={RiSearchLine} fontSize="20" />
    </Flex>
  )
}

import { Box, BoxProps as ChakraBoxProps, useColorModeValue } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface ContainerBoxProps extends ChakraBoxProps {
  children: ReactNode
}

export function ContainerBox({ children, ...rest }: ContainerBoxProps) {
  const bgBox = useColorModeValue('gray.50', 'gray.800')

  return (
    <Box {...rest} bg={bgBox} flex="1" borderRadius={8} p={['6', '8']}>
      {children}
    </Box>
  )
}

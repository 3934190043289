import {
  Avatar,
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Progress,
  Button,
  Stack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiAddLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { ContainerBox } from '../../components/ContainerBox'
import { ResponsiveButton } from '../../components/ResponsiveButton'
import api from '../../services/api'

type ClientProps = {
  id: string
  name: string
  createdAt: Date
}

type UserProps = {
  id: string
  name: string
  isManager: boolean
  avatar: string
  email: string
}

type PlanProps = {
  id: string
  name: string
}

type ApiGetDataResponse = {
  client: ClientProps
  clientTeam: UserProps[]
  plans: PlanProps[]
}

export default function ClientsDetail() {
  const tableColorScheme = useColorModeValue('blackAlpha', 'whiteAlfa')
  const bgBoxCard = useColorModeValue('white', 'gray.900')
  const bgColorBoxUser = useColorModeValue('white', 'gray.700')
  const bgOrange = useColorModeValue('orange.200', 'orange.600')
  const bgBlue = useColorModeValue('blue.200', 'blue.600')
  const colorTextResumo = useColorModeValue('gray.600', 'gray.300')
  const colorTextNome = useColorModeValue('gray.800', 'white')
  const colorTextCargo = useColorModeValue('gray.700', 'gray.200')
  const borderColorBox = useColorModeValue('gray.200', 'gray.700')
  const colorCounterText = useColorModeValue('gray.300', 'gray.500')

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const { clientId } = useParams<{ clientId: string }>()

  const [client, setClient] = useState<ClientProps>({} as ClientProps)
  const [clientTeam, setClientTeam] = useState<UserProps[]>([])

  useEffect(() => {
    async function loadClient() {
      const response = await api.get<ApiGetDataResponse>(`/clients/${clientId}`)
      const clientFromApi = response.data.client
      setClient(clientFromApi)
      setClientTeam(response.data.clientTeam)
    }
    loadClient()
  }, [clientId])

  return (
    <ContainerBox>
      <Box mb="8">
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            {client?.name ?? 'Nome do cliente'}
          </Heading>
          <ResponsiveButton
            as={Link}
            to={`/solicitations/create/${client.id}`}
            text="Nova solicitação"
            isWideVersion={isWideVersion ?? true}
            fontSize="sm"
            size="sm"
            colorScheme="blue"
            icon={RiAddLine}
            iconSize={20}
          />
        </Flex>
        <Box color={colorTextResumo}>
          <Text>3 solicitações em andamento</Text>
          <Text>3 solicitações concluídas</Text>
        </Box>
      </Box>
      <Tabs colorScheme="orange" isFitted variant="line" defaultIndex={0}>
        <TabList>
          <Tab>Solicitações</Tab>
          <Tab>Equipe Mirador</Tab>
          <Tab>Equipe Cliente</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <AccordionButton fontSize="xl">
                  <Box flex="1" textAlign="left">
                    Solicitações em aberto
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Stack spacing="4">
                    {[30, 68, 85, 10].map(item => (
                      <Box
                        borderColor={borderColorBox}
                        borderWidth="1px"
                        borderRadius="8px"
                      >
                        <Button
                          variant="unstyled"
                          as={Link}
                          to="/solicitations/:solicitationId/resume"
                        >
                          <Box py="4" px="2">
                            <Flex justify="space-between" align="bottom">
                              <Text fontSize="md">Nome da solicitação</Text>
                              <Text color={colorCounterText} fontSize="sm">
                                Finaliza em: 30/10/2021
                              </Text>
                            </Flex>
                            <Progress value={item} colorScheme="green" mt="4" mb="2" />
                            <Text color={colorCounterText} fontSize="sm">
                              23 solicitações em 3 sessões. Esperado ao menos 34 arquivos
                            </Text>
                          </Box>
                        </Button>
                      </Box>
                    ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton fontSize="xl">
                  <Box flex="1" textAlign="left">
                    Solicitações encerradas
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Stack spacing="4">
                    {[30, 68, 85, 10].map(item => (
                      <Box
                        borderColor={borderColorBox}
                        borderWidth="1px"
                        borderRadius="8px"
                      >
                        <Button
                          variant="unstyled"
                          as={Link}
                          to="/solicitations/:solicitationId/resume"
                        >
                          <Box py="4" px="2">
                            <Flex justify="space-between" align="bottom">
                              <Text fontSize="md">Nome da solicitação</Text>
                              <Text color={colorCounterText} fontSize="sm">
                                Finalizada em: 20/07/2021
                              </Text>
                            </Flex>
                            <Text color={colorCounterText} fontSize="sm">
                              23 solicitações em 3 sessões. 34 arquivos enviados
                            </Text>
                          </Box>
                        </Button>
                      </Box>
                    ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
          <TabPanel>
            <SimpleGrid minChildWidth="230px" spacing={['4', '6']} w="100%">
              {clientTeam &&
                clientTeam.map(user => (
                  <Box
                    w="100%"
                    maxW="56"
                    bg={bgColorBoxUser}
                    shadow="lg"
                    rounded="lg"
                    overflow="hidden"
                    key={user.id}
                  >
                    <Flex w="full" h="36" align="center" justify="center" bg={bgOrange}>
                      <Avatar size="xl" name={user.name} src={user.avatar} />
                    </Flex>

                    <Box py={5} textAlign="center">
                      <Text
                        display="block"
                        fontSize="xl"
                        color={colorTextNome}
                        fontWeight="bold"
                      >
                        {user.name}
                      </Text>
                      <Text as="span" fontSize="sm" color={colorTextCargo}>
                        {user.isManager ? 'Gestor' : 'Equipe'}
                      </Text>
                      <Text fontSize="sm" color={colorTextCargo}>
                        {user.email}
                      </Text>
                    </Box>
                  </Box>
                ))}
            </SimpleGrid>
          </TabPanel>
          <TabPanel>
            <SimpleGrid minChildWidth="230px" spacing={['4', '6']} w="100%">
              <Box
                w="100%"
                maxW="56"
                bg={bgColorBoxUser}
                shadow="lg"
                rounded="lg"
                overflow="hidden"
              >
                <Flex w="full" h="36" align="center" justify="center" bg={bgBlue}>
                  <Avatar size="xl" name="Nome Gestor" />
                </Flex>

                <Box py={5} textAlign="center">
                  <Text
                    display="block"
                    fontSize="xl"
                    color={colorTextNome}
                    fontWeight="bold"
                  >
                    Nome Administrador
                  </Text>
                  <Text as="span" fontSize="sm" color={colorTextCargo}>
                    Administrador
                  </Text>
                </Box>
              </Box>
              {false &&
                [{ name: '', avatar: '', email: '' }].map(user => (
                  <Box
                    w="100%"
                    maxW="56"
                    bg={bgColorBoxUser}
                    shadow="lg"
                    rounded="lg"
                    overflow="hidden"
                  >
                    <Flex w="full" h="36" align="center" justify="center" bg={bgBlue}>
                      <Avatar size="xl" name={user.name} src={user.avatar} />
                    </Flex>

                    <Box py={5} textAlign="center">
                      <Text
                        display="block"
                        fontSize="xl"
                        color={colorTextNome}
                        fontWeight="bold"
                      >
                        {user.name}
                      </Text>
                      <Text as="span" fontSize="sm" color={colorTextCargo}>
                        Equipe
                      </Text>
                    </Box>
                  </Box>
                ))}
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ContainerBox>
  )
}

import { Flex } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { Header } from '../../../components/Header'
import { Sidebar } from '../../../components/Sidebar'

interface SignedProps {
  children: ReactElement
}

export default function Signed({ children }: SignedProps) {
  return (
    <Flex direction="column" h="100vh">
      <Header />
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />
        {children}
      </Flex>
    </Flex>
  )
}

import { Divider, Heading, useColorModeValue } from '@chakra-ui/react'

type CreationPageHeaderProps = {
  title: string
}

export function CreationPageHeader({ title }: CreationPageHeaderProps) {
  const borderColorDivider = useColorModeValue('gray.200', 'gray.700')
  return (
    <>
      <Heading size="lg" fontWeight="normal">
        {title}
      </Heading>

      <Divider my="6" borderColor={borderColorDivider} />
    </>
  )
}

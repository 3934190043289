import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  Control,
  DeepMap,
  FieldError,
  useFieldArray,
  UseFormRegister,
} from 'react-hook-form'
import { RiDeleteBinLine } from 'react-icons/ri'
import {
  SectionItemListItemFormData,
  SolicitationCreateFormData,
} from '../config/SolicitationCreateFormData'
import api from '../services/api'
import formatValue from '../utils/formatValue'
import { Input } from './Form/Input'
import { Select } from './Form/Select'
import { TextArea } from './Form/TextArea'

type UserProps = {
  id: string
  name: string
  isManager: boolean
}
type PlanProps = {
  id: string
  name: string
}
interface IProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  nestIndex: number
  errors?: DeepMap<SectionItemListItemFormData[], FieldError>
  plans: PlanProps[]
}

export function SectionItemListItem({
  nestIndex,
  control,
  register,
  errors = [],
  plans,
}: IProps<SolicitationCreateFormData>) {
  const borderColorBox = useColorModeValue('gray.200', 'gray.500')
  const colorCounterText = useColorModeValue('gray.200', 'gray.500')

  const { fields, remove, append } = useFieldArray({
    control,
    name: `sections.${nestIndex}.sectionItems`,
  })

  const [users, setUsers] = useState<UserProps[]>([])

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get<{ users: UserProps[] }>('/users')
      const usersFromApi = response.data.users
      setUsers(usersFromApi)
    }
    loadUsers()
  }, [])

  return (
    <Box>
      <Stack spacing="6" my="6">
        {fields.map((item, k) => {
          return (
            <Box
              px="6"
              py="4"
              w="100%"
              borderColor={borderColorBox}
              borderWidth="1px"
              borderRadius="8px"
              key={item.id}
            >
              <Flex w="100%" align="center" justify="space-between">
                <Text textAlign="right" fontWeight="bold" color={colorCounterText}>
                  #{formatValue(k + 1)}
                </Text>
                <IconButton
                  size="sm"
                  disabled={fields.length === 1}
                  colorScheme="red"
                  variant="link"
                  icon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                  aria-label="Deletar item"
                  onClick={() => remove(k)}
                />
              </Flex>
              <VStack spacing="6" w="100%" mt="2">
                <TextArea
                  error={
                    errors !== undefined && errors[k] !== undefined
                      ? errors[k]?.description
                      : undefined
                  }
                  {...register(`sections.${nestIndex}.sectionItems.${k}.description`)}
                  label="Descrição da solicitação"
                />
                <input
                  type="hidden"
                  {...register(`sections.${nestIndex}.sectionItems.${k}.itemPosition`, {
                    value: k,
                  })}
                />
                <FormControl isInvalid={errors[k]?.plans !== undefined}>
                  <FormLabel htmlFor="isRequired"> Planos</FormLabel>
                  <CheckboxGroup colorScheme="orange">
                    <SimpleGrid
                      minChildWidth={['240px', '180px']}
                      spacing={['4', '6']}
                      w="100%"
                    >
                      {plans.map(plan => (
                        <Checkbox
                          value={plan.id}
                          key={plan.id}
                          {...register(`sections.${nestIndex}.sectionItems.${k}.plans`)}
                        >
                          {plan.name}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </CheckboxGroup>
                  {!!errors[k]?.plans && <FormErrorMessage>...</FormErrorMessage>}
                </FormControl>
                <Select
                  error={errors[k]?.responsibleClientUserId}
                  {...register(
                    `sections.${nestIndex}.sectionItems.${k}.responsibleClientUserId`,
                  )}
                  label="Usuário cliente responsável"
                  placeholder="Responsável pelo envio"
                  options={users.map(user => ({ value: user.id, text: user.name }))}
                />
                <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
                  <Input
                    type="date"
                    error={errors[k]?.referenceDate}
                    {...register(`sections.${nestIndex}.sectionItems.${k}.referenceDate`)}
                    label="Data de referência do item"
                  />
                  <Input
                    type="date"
                    error={errors[k]?.limitDate}
                    {...register(`sections.${nestIndex}.sectionItems.${k}.limitDate`)}
                    label="Data limite de entrega"
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
                  <FormControl
                    isInvalid={errors !== undefined && !!errors[k]?.isRequired}
                  >
                    <FormLabel htmlFor="isRequired"> Campo obrigatório</FormLabel>
                    <Checkbox
                      colorScheme="orange"
                      {...register(`sections.${nestIndex}.sectionItems.${k}.isRequired`)}
                    >
                      É obrigatório
                    </Checkbox>

                    <FormErrorMessage>
                      {errors !== undefined ? /* errors[k]?.type?.message */ '' : null}
                    </FormErrorMessage>
                  </FormControl>
                  <Input
                    type="file"
                    error={
                      errors !== undefined && errors[k] !== undefined
                        ? errors[k]?.fileModel
                        : undefined
                    }
                    {...register(`sections.${nestIndex}.sectionItems.${k}.fileModel`)}
                    label="Arquivo Modelo"
                  />
                </SimpleGrid>
              </VStack>
            </Box>
          )
        })}
      </Stack>

      <Button colorScheme="blue" onClick={() => append({})}>
        Novo Item
      </Button>
    </Box>
  )
}

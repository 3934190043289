/* eslint-disable prettier/prettier */
import {
  Button,
  Flex,
  HStack,
  useToast,
  VStack,
  Text,
  CheckboxGroup,
  Checkbox,
  FormControl,
  SimpleGrid,
  FormLabel,
  FormErrorMessage,
  Box,
  IconButton,
  Icon,
  useColorModeValue,
  Stack,
  Divider,
} from '@chakra-ui/react'
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Link, useHistory, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useState, useEffect, useCallback } from 'react'
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri'
import { Input } from '../../components/Form/Input'
import api from '../../services/api'
import { isOfType } from '../../utils/isOfType'
import { AppError } from '../../config/AppErrorType'
import { ContainerBox } from '../../components/ContainerBox'
import { CreationPageHeader } from '../../components/CreationPageHeader'
import { Select } from '../../components/Form/Select'
import { Toast } from '../../components/Toast'
import { SectionItemList } from '../../components/SectionItemList'
import { SolicitationCreateFormData } from '../../config/SolicitationCreateFormData'


type ClientCategoryProps = {
  name: string
  id: string
}


type UserProps = {
  id: string
  name: string
  isManager: boolean
}

type PlanProps = {
  id: string
  name: string
}

type ClientProps = {
  id: string
  name: string
}

type ApiGetDataResponse = {
  client: ClientProps
  clientTeam: UserProps[]
  plans: PlanProps[]
}


const solicitationsCreateFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  managerId: yup.string().required('Gestor obrigatório'),
  clientUser: yup.object().shape({
    name: yup.string().required('Nome do usuário obrigatório'),
    email: yup
      .string()
      .required('E-mail do usuário obrigatório')
      .email('E-mail inválido'),
  }),
  clientCategories: yup
    .array()
    .of(yup.string())
    .typeError('Selecione ao menos uma categoria')
    .min(1, 'Selecione ao menos uma categoria'),

  clientTeam: yup
    .array()
    .of(yup.string())
    .typeError('Selecione ao menos uma pessoa para a equipe')
    .min(1, 'Selecione ao menos uma pessoa para a equipe'),
  plans: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Nome do plano é obrigatório'),
      isRequired: yup.boolean(),
    })
  ).typeError('Crie ao menos um plano para o cliente')
    .min(1, 'Crie ao menos um plano para o cliente'),
})

export default function SolicitationsCreate() {
  const borderColorBox = useColorModeValue('gray.200', 'gray.700')
  const history = useHistory()

  const toast = useToast()

  const { clientId } = useParams<{ clientId: string }>()

  const [client, setClient] = useState<ClientProps>({} as ClientProps)
  const [clientTeam, setClientTeam] = useState<UserProps[]>([])
  const [clientPlans, setClientPlans] = useState<PlanProps[]>([])

  useEffect(() => {
    async function loadClientInfos() {
      if (clientId) {
        try {
          const response = await api.get<ApiGetDataResponse>(`/clients/${clientId}`)
          setClient(response.data.client)
          setClientTeam(response.data.clientTeam)
          setClientPlans(response.data.plans)
          console.log(response.data.clientTeam.filter(user => user.isManager).map(user => user.id))
        } catch (err) {
          console.log(err)
        }
      }
    }

    loadClientInfos()

  }, [clientId])




  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState
  } = useForm<SolicitationCreateFormData>({
    // resolver: yupResolver(solicitationsCreateFormSchema),
  });

  const { errors } = formState




  const [users, setUsers] = useState<UserProps[]>([])

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get<{ users: UserProps[] }>('/users')
      const usersFromApi = response.data.users
      setUsers(usersFromApi)
    }
    loadUsers()
  }, [])

  const handleCreateClient: SubmitHandler<SolicitationCreateFormData> = async data => {
    console.log(data)

    try {
      toast({
        render: () => (
          <Toast
            title="Creating new solicitation"
            description="Nova solicitação sendo criada..."
            status="info"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      await new Promise(resolve => setTimeout(resolve, 3200))
      // const response = await api.post<{ user: UserProps }>('/users', data)
      // const createdUser = response.data.user

      toast({
        render: () => (
          <Toast
            title="Solicitation created"
            description="Solicitation created successfully"
            status="success"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })
      history.push(`/clients/${clientId}/details`)
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao criar conta'
      toast({
        render: () => (
          <Toast
            title="Section model creation error"
            description={errorMessage}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: false,
        position: 'bottom-left',
      })
    }
  }

  return (
    <ContainerBox as="form" onSubmit={handleSubmit(handleCreateClient)}>
      <CreationPageHeader title="Criar solicitação de dados" />
      <VStack spacing="8">
        <Input
          error={errors.title} {...register('title')}
          label="Título da solicitação"
        />
        <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
          <Input
            type="date"
            error={errors.limitDate}
            {...register('limitDate')}
            label="Data limite de entrega"
          />
          <Select
            error={errors.clientUserId}
            {...register('clientUserId')}
            label="Usuário cliente responsável"
            placeholder="Responsável geral do cliente"
            options={users.map(user => ({ value: user.id, text: user.name }))}
          />
        </SimpleGrid>
        <FormControl isInvalid={errors.clientTeamToNotificate !== undefined}>
          <FormLabel htmlFor="isRequired"> Equipe Mirador a ser notificada</FormLabel>
          <CheckboxGroup
            colorScheme="orange"

          >
            <SimpleGrid minChildWidth={['240px', '180px']} spacing={['4', '6']} w="100%">
              {clientTeam && clientTeam.map(user => (
                <Checkbox
                  value={user.id} key={user.id} {...register('clientTeamToNotificate')}

                >
                  {user.name}{' '}{user.isManager && '(Gestor)'}
                </Checkbox>
              ))}
            </SimpleGrid>
          </CheckboxGroup>
          {!!errors.clientTeamToNotificate && (
            <FormErrorMessage>...</FormErrorMessage>
          )}
        </FormControl>
        <Divider />
        <SectionItemList
          {...{ control, register, getValues, setValue, errors, plans: clientPlans }}
        />

      </VStack>
      <Divider />

      <Flex mt={['6', '8']} justify="flex-end">

        <HStack spacing="4">
          <Button as={Link} to="/clients" colorScheme="gray">
            Cancelar
          </Button>
          <Button isLoading={formState.isSubmitting} colorScheme="orange" type="submit">
            Salvar
          </Button>
        </HStack>
      </Flex>
    </ContainerBox >
  )
}

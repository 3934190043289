import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ContainerBox } from '../../components/ContainerBox'
import { Pagination } from '../../components/Pagination'
import { ResponsiveButton } from '../../components/ResponsiveButton'
import { Toast } from '../../components/Toast'
import { AppError } from '../../config/AppErrorType'
import api from '../../services/api'
import { isOfType } from '../../utils/isOfType'

type ClientCategoryProps = {
  id: string
  name: string
}

export default function ClientCategories() {
  const toast = useToast()
  const tableColorScheme = useColorModeValue('blackAlpha', 'whiteAlfa')

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const [clientCategories, setClientCategories] = useState<ClientCategoryProps[]>([])

  useEffect(() => {
    async function loadClientCategories() {
      const response = await api.get<{ categories: ClientCategoryProps[] }>(
        '/clients/categories',
      )
      const { categories } = response.data
      setClientCategories(categories)
    }
    loadClientCategories()
  }, [])

  const [removableItem, setRemovableItem] = useState<ClientCategoryProps>(
    {} as ClientCategoryProps,
  )
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const onCloseAlert = () => {
    setIsOpenAlert(false)
    setRemovableItem({} as ClientCategoryProps)
  }
  const cancelRefAlert = useRef(null)
  const handleOpenDialogRemoveModel = useCallback((itemToRemove: ClientCategoryProps) => {
    setRemovableItem(itemToRemove)
    setIsOpenAlert(true)
  }, [])
  const handleRemoveModel = useCallback(async () => {
    try {
      toast({
        render: () => (
          <Toast
            title="Removing client category"
            description="Remoção em andamento..."
            status="info"
          />
        ),
        duration: 2000,
        isClosable: false,
        position: 'bottom-left',
      })
      await api.delete(`/clients/categories/${removableItem.id}`)

      toast({
        render: () => (
          <Toast
            title="Client category removed"
            description="Client category removed successfully"
            status="success"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      setClientCategories(items => items.filter(item => item.id !== removableItem.id))

      onCloseAlert()
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao remover modelo'

      toast({
        render: () => (
          <Toast
            title="Section model remove error"
            description={errorMessage}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: false,
        position: 'bottom-left',
      })
    }
  }, [removableItem, toast])

  return (
    <>
      <ContainerBox>
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Categorias de clientes
          </Heading>
          <ResponsiveButton
            as={Link}
            to="/clients/categories/create"
            text="Criar novo"
            isWideVersion={isWideVersion ?? true}
            fontSize="sm"
            size="sm"
            colorScheme="orange"
            icon={RiAddLine}
            iconSize={20}
          />
        </Flex>
        <Table colorScheme={tableColorScheme}>
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="orange" />
              </Th>
              <Th>Categoria</Th>
              <Th width="8" />
            </Tr>
          </Thead>
          <Tbody>
            {clientCategories.map(clientCategory => (
              <Tr key={clientCategory.id}>
                <Td px={['4', '4', '6']}>
                  <Checkbox colorScheme="orange" />
                </Td>
                <Td>
                  <Text fontWeight="bold">{clientCategory.name}</Text>
                </Td>

                <Td>
                  <ResponsiveButton
                    text="Remover"
                    isWideVersion={isWideVersion ?? true}
                    fontSize="sm"
                    size="sm"
                    colorScheme="red"
                    icon={RiDeleteBinLine}
                    iconSize={16}
                    onClick={() => handleOpenDialogRemoveModel(clientCategory)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination />
      </ContainerBox>

      <AlertDialog
        leastDestructiveRef={cancelRefAlert}
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover categoria de cliente
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja remover a categoria{' '}
              <Text as="strong">{removableItem.name}</Text>? Essa ação não pode ser
              desfeita.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleRemoveModel} ml={3}>
                Remover
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

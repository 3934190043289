import { Button, useColorModeValue } from '@chakra-ui/react'

interface PaginationItemProps {
  number: number
  isCurrent?: boolean
}

export function PaginationItem({ isCurrent = false, number }: PaginationItemProps) {
  const bgButtonDisabled = useColorModeValue('orange.500', 'orange.200')

  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        colorScheme="orange"
        disabled
        _disabled={{ bg: bgButtonDisabled, cursor: 'default' }}
      >
        {number}
      </Button>
    )
  }

  return (
    <Button size="sm" fontSize="xs" width="4" colorScheme="gray">
      {number}
    </Button>
  )
}

import {
  Button,
  Flex,
  HStack,
  SimpleGrid,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Link, useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Input } from '../../components/Form/Input'
import api from '../../services/api'
import { isOfType } from '../../utils/isOfType'
import { AppError } from '../../config/AppErrorType'
import { ContainerBox } from '../../components/ContainerBox'
import { CreationPageHeader } from '../../components/CreationPageHeader'

type UsersCreateFormData = {
  email: string
  name: string
  password: string
  passwordConfirmation: string
}

type UserProps = {
  id: string
  name: string
  email: string
  createdAt: Date
  avatar: string
}

const usersCreateFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  password: yup.string().required('Senha obrigatória').min(6, 'No mínimo 6 caracteres'),
  passwordConfirmation: yup
    .string()
    .oneOf([null, yup.ref('password')], 'A senha e confirmação devem ser iguais.'),
})

export default function UsersCreate() {
  const history = useHistory()

  const toast = useToast()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(usersCreateFormSchema),
  })
  const { errors } = formState

  const handleCreateUser: SubmitHandler<UsersCreateFormData> = async data => {
    try {
      const response = await api.post<{ user: UserProps }>('/users', data)
      const createdUser = response.data.user

      toast({
        title: 'Account created',
        description: (
          <Text>
            Account for user{' '}
            <Text as="span" fontWeight="bold">
              {createdUser.name}
            </Text>{' '}
            created successfully
          </Text>
        ),
        status: 'success',
        duration: 2500,
        isClosable: false,
        position: 'top',
      })
      history.push('/users')
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao criar conta'
      toast({
        title: 'Account creation error',
        description: errorMessage,
        status: 'error',
        duration: 2500,
        isClosable: false,
        position: 'top',
      })
    }
  }

  return (
    <ContainerBox as="form" onSubmit={handleSubmit(handleCreateUser)}>
      <CreationPageHeader title="Criar usuário" />
      <VStack spacing="8">
        <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
          <Input error={errors.name} {...register('name')} label="Nome" />
          <Input
            error={errors.email}
            {...register('email')}
            type="email"
            label="E-mail"
          />
        </SimpleGrid>
        <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
          <Input
            error={errors.password}
            {...register('password')}
            type="password"
            label="Senha"
          />
          <Input
            error={errors.passwordConfirmation}
            {...register('passwordConfirmation')}
            type="password"
            label="Confirmação da senha"
          />
        </SimpleGrid>
      </VStack>
      <Flex mt={['6', '8']} justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to="/users" colorScheme="gray">
            Cancelar
          </Button>
          <Button isLoading={formState.isSubmitting} colorScheme="orange" type="submit">
            Salvar
          </Button>
        </HStack>
      </Flex>
    </ContainerBox>
  )
}

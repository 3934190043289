import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SidebarDrawerProvider } from './contexts/SidebarDrawerContext'
import AppProvider from './hooks'
import Routes from './routes'
import { theme } from './styles/theme'

export const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <SidebarDrawerProvider>
        <AppProvider>
          <Router>
            <Routes />
          </Router>
        </AppProvider>
      </SidebarDrawerProvider>
    </ChakraProvider>
  )
}

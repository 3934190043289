import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraTextareaProps {
  name: string

  label?: string
  error?: FieldError
}

const TextAreaBase: ForwardRefRenderFunction<HTMLTextAreaElement, InputProps> = (
  { name, label, error = null, ...rest },
  ref,
) => {
  const bgChakraTextarea = useColorModeValue('whiteAlpha.900', 'gray.900')
  const focusBorderChakraTextarea = useColorModeValue('orange.500', 'orange.400')
  const focusBgColorChakraTextarea = useColorModeValue('gray.400', 'gray.200')
  const focusColorChakraTextarea = useColorModeValue('white', 'gray.800')
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}> {label}</FormLabel>}
      <ChakraTextarea
        name={name}
        id={name}
        focusBorderColor={focusBorderChakraTextarea}
        bgColor={bgChakraTextarea}
        _focus={{ bgColor: focusBgColorChakraTextarea, color: focusColorChakraTextarea }}
        variant="filled"
        _hover={{
          bgColor: bgChakraTextarea,
        }}
        size="lg"
        {...rest}
        ref={ref}
        resize="none"
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const TextArea = forwardRef(TextAreaBase)

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraInputProps {
  name: string

  label?: string
  error?: FieldError
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, error = null, ...rest },
  ref,
) => {
  const bgChakraInput = useColorModeValue('whiteAlpha.900', 'gray.900')
  const focusBorderChakraInput = useColorModeValue('orange.500', 'orange.400')
  const focusBgColorChakraInput = useColorModeValue('gray.400', 'gray.200')
  const focusColorChakraInput = useColorModeValue('white', 'gray.800')
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}> {label}</FormLabel>}
      <ChakraInput
        name={name}
        id={name}
        focusBorderColor={focusBorderChakraInput}
        bgColor={bgChakraInput}
        _focus={{ bgColor: focusBgColorChakraInput, color: focusColorChakraInput }}
        variant="filled"
        _hover={{
          bgColor: bgChakraInput,
        }}
        size="lg"
        {...rest}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)

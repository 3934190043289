import {
  HStack,
  Icon,
  IconButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  RiLogoutBoxRLine,
  RiMoonLine,
  RiNotificationLine,
  RiSunLine,
} from 'react-icons/ri'
import { useAuth } from '../../hooks/auth'

export function NotificationsNav() {
  const { colorMode, toggleColorMode } = useColorMode()
  const borderColorHStack = useColorModeValue('gray.200', 'gray.700')
  const colorHStack = useColorModeValue('gray.500', 'gray.300')

  const { signOut } = useAuth()
  return (
    <HStack
      spacing={['1', '2']}
      mx={['3', '6']}
      pr={['1', '6']}
      py="1"
      color={colorHStack}
      borderRightWidth={1}
      borderColor={borderColorHStack}
    >
      <IconButton
        aria-label="notifications"
        onClick={() => null}
        variant="unstyled"
        icon={<Icon as={RiNotificationLine} fontSize="20" />}
      />
      <IconButton
        aria-label="toggle theme"
        onClick={toggleColorMode}
        variant="unstyled"
        icon={
          colorMode === 'light' ? (
            <Icon as={RiMoonLine} fontSize="20" />
          ) : (
            <Icon as={RiSunLine} fontSize="20" />
          )
        }
      />
      <IconButton
        aria-label="SignOut"
        onClick={signOut}
        variant="unstyled"
        icon={<Icon as={RiLogoutBoxRLine} fontSize="20" />}
      />
    </HStack>
  )
}

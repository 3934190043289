import { ColorModeScript } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import { theme } from './styles/theme'

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript nonce="true" initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  colors: {
    gray: {
      '900': '#181B23',
      '800': '#1F2029',
      '700': '#353646',
      '600': '#4B4D63',
      '500': '#616480',
      '400': '#797D9A',
      '300': '#9699B0',
      '200': '#B3B5C6',
      '100': '#D1D2DC',
      '50': '#EEEEF2',
    },
    orange: {
      '900': '#723e09',
      '800': '#a1580d',
      '700': '#b8650f',
      '600': '#d07111',
      '500': '#ed8b27',
      '400': '#f1a456',
      '300': '#f3b16e',
      '200': '#f5be85',
      '100': '#f7ca9d',
      '50': '#fbe3cc',
    },
  },
  fonts: {
    heading: 'Lato',
    body: 'Lato',
  },
  styles: {
    global: props => ({
      body: {
        bg: props.colorMode === 'dark' && 'gray.900',
      },
    }),
  },
})

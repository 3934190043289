import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
  IconButton,
  VStack,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ModalProps,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RiAddLine, RiDeleteBinLine, RiPencilLine, RiSearchEyeLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { AxiosError } from 'axios'
import { Pagination } from '../../components/Pagination'
import api from '../../services/api'
import { ContainerBox } from '../../components/ContainerBox'
import { ResponsiveButton } from '../../components/ResponsiveButton'
import formatValue from '../../utils/formatValue'
import { Toast } from '../../components/Toast'
import { AppError } from '../../config/AppErrorType'
import { isOfType } from '../../utils/isOfType'

type SectionModelItemProps = {
  id: string
  description: string
  isRequired: boolean
  sectionModelId: string
  createdAt: Date
  fileModel: string
}
type SectionModelProps = {
  id: string
  title: string
  createdAt: Date
  sectionModelItems: SectionModelItemProps[]
}

export default function SectionModels() {
  const borderColorBoxItemModal = useColorModeValue('gray.100', 'gray.800')
  const colorCounterTextModal = useColorModeValue('gray.200', 'gray.400')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [sectionModels, setSectionModels] = useState<SectionModelProps[]>([])
  const [modalData, setModalData] = useState<SectionModelProps>({} as SectionModelProps)
  const [removableItem, setRemovableItem] = useState<SectionModelProps>(
    {} as SectionModelProps,
  )

  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const onCloseAlert = () => {
    setIsOpenAlert(false)
    setRemovableItem({} as SectionModelProps)
  }
  const cancelRefAlert = useRef(null)

  useEffect(() => {
    async function loadSectionModels() {
      const response = await api.get<{ sectionModels: SectionModelProps[] }>(
        '/sections/models',
      )
      const sectionModelsFromApi = response.data.sectionModels
      setSectionModels(sectionModelsFromApi)
    }
    loadSectionModels()
  }, [])

  function openModal(sectionModel: SectionModelProps) {
    setModalData(sectionModel)
    onOpen()
  }
  const handleOpenDialogRemoveModel = useCallback((itemToRemove: SectionModelProps) => {
    setRemovableItem(itemToRemove)
    setIsOpenAlert(true)
  }, [])

  const handleRemoveModel = useCallback(async () => {
    try {
      toast({
        render: () => (
          <Toast
            title="Removing section model"
            description="Remoção em andamento..."
            status="info"
          />
        ),
        duration: 2000,
        isClosable: false,
        position: 'bottom-left',
      })
      await api.delete(`/sections/models/${removableItem.id}`)

      toast({
        render: () => (
          <Toast
            title="Section model removed"
            description="Section model removed successfully"
            status="success"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      setSectionModels(items => items.filter(item => item.id !== removableItem.id))

      onCloseAlert()
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao remover modelo'

      toast({
        render: () => (
          <Toast
            title="Section model remove error"
            description={errorMessage}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: false,
        position: 'bottom-left',
      })
    }
  }, [removableItem, toast])

  return (
    <>
      <ContainerBox>
        <Flex mb="8" justify="space-between" align="center" direction={['column', 'row']}>
          <Heading size="lg" fontWeight="normal">
            Modelos de sessão
          </Heading>

          <ResponsiveButton
            as={Link}
            to="/solicitations/section-models/create"
            text="Criar novo"
            isWideVersion={isWideVersion ?? true}
            fontSize="sm"
            size="sm"
            colorScheme="orange"
            icon={RiAddLine}
            iconSize={20}
          />
        </Flex>
        <Table colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th px={['4', '4', '6']} color="gray.300" width="8">
                <Checkbox colorScheme="orange" />
              </Th>
              <Th>Título da Sessão</Th>
              {isWideVersion && <Th>Data de cadastro</Th>}
              <Th width="16" />
            </Tr>
          </Thead>
          <Tbody>
            {sectionModels.map(sectionModel => (
              <Tr key={sectionModel.id}>
                <Td px={['4', '4', '6']}>
                  <Checkbox colorScheme="orange" />
                </Td>
                <Td>
                  <Box>
                    <Text fontWeight="bold">{sectionModel.title}</Text>
                    <Text fontSize="sm">
                      {sectionModel.sectionModelItems.length}{' '}
                      {sectionModel.sectionModelItems.length === 1 ? 'item' : 'itens'}
                    </Text>
                  </Box>
                </Td>
                {isWideVersion && (
                  <Td>{format(new Date(sectionModel.createdAt), 'dd/MM/yyy')}</Td>
                )}
                <Td>
                  <HStack spacing="4">
                    <ResponsiveButton
                      text="Ver"
                      isWideVersion={isWideVersion ?? true}
                      fontSize="sm"
                      size="sm"
                      colorScheme="blue"
                      icon={RiSearchEyeLine}
                      iconSize={16}
                      onClick={() => openModal(sectionModel)}
                    />
                    <ResponsiveButton
                      as={Link}
                      to={`/solicitations/section-models/${sectionModel.id}`}
                      text="Editar"
                      isWideVersion={isWideVersion ?? true}
                      fontSize="sm"
                      size="sm"
                      colorScheme="teal"
                      icon={RiPencilLine}
                      iconSize={16}
                    />
                    <ResponsiveButton
                      text="Remover"
                      isWideVersion={isWideVersion ?? true}
                      fontSize="sm"
                      size="sm"
                      colorScheme="red"
                      icon={RiDeleteBinLine}
                      iconSize={16}
                      onClick={() => handleOpenDialogRemoveModel(sectionModel)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination />
      </ContainerBox>

      <Modal size="3xl" preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {modalData.title}
            <Text fontSize="sm" fontWeight="normal">
              {modalData.sectionModelItems?.length}{' '}
              {modalData.sectionModelItems?.length === 1 ? 'item' : 'itens'}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="4">
              {modalData.sectionModelItems &&
                modalData.sectionModelItems.map((item, index) => (
                  <Box
                    p="4"
                    w="100%"
                    borderColor={borderColorBoxItemModal}
                    borderWidth="1px"
                    borderRadius="8px"
                    key={item.id}
                  >
                    <Text as="span" fontSize="smaller" color={colorCounterTextModal}>
                      #{formatValue(index + 1)}{' '}
                    </Text>
                    <Text fontWeight="bold" fontSize="lg">
                      {item.description}
                    </Text>
                    <Flex align="center" justify="space-between">
                      <Text>O item é {item.isRequired ? 'obrigatório' : 'opcional'}</Text>
                      {item.fileModel !== null ? (
                        <Button
                          variant="link"
                          as="a"
                          download
                          href={`http://localhost:3333/files/${item.fileModel}`}
                        >
                          Baixar modelo
                        </Button>
                      ) : (
                        <Text>Não há modelo de arquivo para este item</Text>
                      )}
                    </Flex>
                  </Box>
                ))}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="solid" colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        leastDestructiveRef={cancelRefAlert}
        isOpen={isOpenAlert}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover modelo de sessão
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja remover o modelo{' '}
              <Text as="strong">{removableItem.title}</Text>? Essa ação não pode ser
              desfeita.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefAlert} onClick={onCloseAlert}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleRemoveModel} ml={3}>
                Remover
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

import {
  Flex,
  Button,
  Stack,
  useToast,
  useColorModeValue,
  IconButton,
  Icon,
  useColorMode,
} from '@chakra-ui/react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { AxiosError } from 'axios'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'
import { Input } from '../../components/Form/Input'
import { useAuth } from '../../hooks/auth'
import { isOfType } from '../../utils/isOfType'
import { AppError } from '../../config/AppErrorType'

type SignInFormData = {
  email: string
  password: string
}

const signInFormSchema = yup.object().shape({
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  password: yup.string().required('Senha obrigatória'),
})

export default function SignIn() {
  const { colorMode, toggleColorMode } = useColorMode()

  const bgFlex = useColorModeValue('gray.50', 'gray.800')

  const { signIn } = useAuth()
  const toast = useToast()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(signInFormSchema),
  })
  const { errors } = formState

  const handleSignIn: SubmitHandler<SignInFormData> = async data => {
    // await new Promise(resolve => setTimeout(resolve, 2000))
    try {
      await signIn({ email: data.email, password: data.password })
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message
      toast({
        title: 'SignIn Error',
        description: errorMessage,
        status: 'error',
        duration: 2500,
        isClosable: false,
        position: 'top',
      })
    }
  }

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        flexDir="column"
        as="form"
        w="100%"
        maxWidth={360}
        bg={bgFlex}
        p="8"
        borderRadius="8"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Stack spacing={4}>
          <Input
            label="E-mail"
            error={errors.email}
            type="email"
            {...register('email')}
          />
          <Input
            label="Senha"
            error={errors.password}
            type="password"
            {...register('password')}
          />
        </Stack>
        <Button
          isLoading={formState.isSubmitting}
          type="submit"
          mt="6"
          colorScheme="orange"
          size="lg"
        >
          Entrar
        </Button>
        <Flex mt="8" direction="row" align="center" justify="space-between">
          <IconButton
            w="auto"
            aria-label="toggle theme"
            onClick={toggleColorMode}
            variant="unstyled"
            icon={
              colorMode === 'light' ? (
                <Icon as={RiMoonLine} fontSize="20" />
              ) : (
                <Icon as={RiSunLine} fontSize="20" />
              )
            }
          />
          <Button as={Link} to="/recovery" variant="link">
            Esqueci a senha
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

import { Box, Text, Flex, Icon, useColorModeValue } from '@chakra-ui/react'
import {
  RiCheckboxCircleFill,
  RiInformationFill,
  RiAlertFill,
  RiStopCircleFill,
} from 'react-icons/ri'

type ToastProps = {
  description: string
  title?: string
  status: 'success' | 'info' | 'warning' | 'error'
}

export function Toast({ description, title, status }: ToastProps) {
  const bgToast = useColorModeValue('white', 'gray.800')
  const colorSuccess = useColorModeValue('green.500', 'green.400')
  const colorInfo = useColorModeValue('blue.500', 'blue.400')
  const colorWarning = useColorModeValue('yellow.400', 'yellow.300')
  const colorError = useColorModeValue('red.500', 'red.400')
  const colorText = useColorModeValue('gray.600', 'gray.200')

  const toastContent = {
    success: {
      icon: RiCheckboxCircleFill,
      textColor: colorSuccess,
      title: 'Success',
      bg: 'green.500',
    },
    info: {
      icon: RiInformationFill,
      textColor: colorInfo,
      title: 'Info',
      bg: 'blue.500',
    },
    warning: {
      icon: RiAlertFill,
      textColor: colorWarning,
      title: 'Warning',
      bg: 'yellow.500',
    },
    error: {
      icon: RiStopCircleFill,
      textColor: colorError,
      title: 'Error',
      bg: 'red.500',
    },
  }

  return (
    <Flex
      maxW="sm"
      w="full"
      mx="auto"
      bg={bgToast}
      shadow="md"
      rounded="lg"
      overflow="hidden"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        w="16"
        bg={toastContent[status].textColor}
      >
        <Icon as={toastContent[status].icon} color="white" boxSize="8" />
      </Flex>

      <Box mx={-3} py={2} px={4}>
        <Box mx={3}>
          <Text as="span" color={toastContent[status].textColor} fontWeight="bold">
            {title || toastContent[status].title}
          </Text>
          <Text color={colorText} fontSize="sm">
            {description}
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}

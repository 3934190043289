import {
  Box,
  Button,
  Flex,
  Stack,
  useColorModeValue,
  Text,
  IconButton,
  Icon,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DeepMap,
  Control,
  FieldError,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'

import { RiDeleteBinLine } from 'react-icons/ri'
import {
  SolicitationCreateFormData,
  SectionItemListItemFormData,
} from '../config/SolicitationCreateFormData'
import api from '../services/api'
import formatValue from '../utils/formatValue'
import { Input } from './Form/Input'
import { Select } from './Form/Select'
import { SectionItemListItem } from './SectionItemListItem'

let renderCount = 0

type SectionModelItemProps = {
  id: string
  description: string
  isRequired: boolean
  sectionModelId: string
  createdAt: Date
  fileModel: string
  itemPosition: number
}
type SectionModelProps = {
  id: string
  title: string
  createdAt: Date
  sectionModelItems: SectionModelItemProps[]
}

type PlanProps = {
  id: string
  name: string
}
interface IProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  setValue: UseFormSetValue<T>
  getValues: UseFormGetValues<T>
  errors?: DeepMap<T, FieldError>
  plans: PlanProps[]
}
export function SectionItemList({
  control,
  register,
  setValue,
  getValues,
  errors = {},
  plans,
}: IProps<SolicitationCreateFormData>) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sections',
  })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const refImportModal = useRef(null)
  const [sectionModelImportId, setSectionModelImportId] = useState('')

  const bgBoxCard = useColorModeValue('gray.100', 'gray.700')
  const colorTextResumo = useColorModeValue('gray.600', 'gray.300')
  const colorCounterText = useColorModeValue('gray.200', 'gray.500')

  renderCount += 1

  const [sectionModels, setSectionModels] = useState<SectionModelProps[]>([])
  useEffect(() => {
    async function loadSectionModels() {
      const response = await api.get<{ sectionModels: SectionModelProps[] }>(
        '/sections/models',
      )
      const sectionModelsFromApi = response.data.sectionModels
      setSectionModels(sectionModelsFromApi)
    }
    loadSectionModels()
  }, [])

  const handleImport = useCallback(() => {
    const sectionModelToImport = sectionModels.find(v => v.id === sectionModelImportId)
    if (sectionModelToImport) {
      const modelItems = sectionModelToImport.sectionModelItems.map(item => {
        return {
          description: item.description,
          itemPosition: item.itemPosition,
          isRequired: item.isRequired,
          fileModel: '',
          plans: [] as string[],
          responsibleClientUserId: '',
          referenceDate: '',
          limitDate: '',
        } as unknown as SectionItemListItemFormData
      })
      append({
        title: sectionModelToImport.title,
        sectionItems: modelItems,
      })
    }
    onClose()
  }, [sectionModelImportId, sectionModels, onClose, append])

  return (
    <>
      <Box w="100%">
        <Stack spacing="6">
          {fields.map((item, index) => {
            const thisSectionErrors =
              errors?.sections !== undefined ? errors.sections[index] : {}
            return (
              <Box w="100%" key={item.id}>
                <Box
                  mx="auto"
                  px={8}
                  py={4}
                  rounded="lg"
                  shadow="lg"
                  bg={bgBoxCard}
                  w="100%"
                >
                  <Text textAlign="right" fontWeight="bold" color={colorCounterText}>
                    #{formatValue(index + 1)}
                  </Text>
                  <Flex w="100%" align="flex-end" justify="space-between">
                    <Input
                      label="Título da sessão"
                      error={thisSectionErrors?.title ?? undefined}
                      {...register(`sections.${index}.title`)}
                    />
                    <Box ml="3" pb="2">
                      <IconButton
                        size="sm"
                        disabled={fields.length === 1}
                        colorScheme="red"
                        variant="link"
                        icon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                        aria-label="Deletar item"
                        onClick={() => remove(index)}
                      />
                    </Box>
                  </Flex>

                  <SectionItemListItem
                    nestIndex={index}
                    {...{
                      control,
                      register,
                      errors: thisSectionErrors?.sectionItems,
                      plans,
                    }}
                  />
                </Box>{' '}
              </Box>
            )
          })}
        </Stack>
        <Flex my="8" align="center" justify="space-around">
          <Button
            colorScheme="purple"
            type="button"
            onClick={() => {
              append({})
            }}
          >
            Nova sessão vazia
          </Button>
          <Button colorScheme="teal" type="button" onClick={onOpen}>
            Nova sessão de modelo
          </Button>
        </Flex>
      </Box>

      <Modal size="3xl" preserveScrollBarGap isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Importação de sessão modelo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg" mb="4">
              Selecione um modelo de sessão abaixo para fazer a importação:
            </Text>

            <Select
              ref={refImportModal}
              name="sectionModelToImport"
              label="Modelo de sessão"
              placeholder="Sessão para importar"
              options={sectionModels.map(sectionModel => ({
                value: sectionModel.id,
                text: sectionModel.title,
              }))}
              onChange={e => setSectionModelImportId(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="solid" colorScheme="gray" mr={3} onClick={onClose}>
              Fechar
            </Button>

            <Button variant="solid" colorScheme="orange" mr={3} onClick={handleImport}>
              Importar
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { RiAddLine, RiPencilLine, RiSearchEyeLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ContainerBox } from '../../components/ContainerBox'
import { Pagination } from '../../components/Pagination'
import { ResponsiveButton } from '../../components/ResponsiveButton'
import api from '../../services/api'

type ClientProps = {
  id: string
  name: string
  createdAt: Date
}

export default function Clients() {
  const bgBoxCard = useColorModeValue('white', 'gray.900')
  const colorTextResumo = useColorModeValue('gray.600', 'gray.300')

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const [clients, setClients] = useState<ClientProps[]>([])

  useEffect(() => {
    async function loadClients() {
      const response = await api.get<{ clients: ClientProps[] }>('/clients')
      const clientsFromApi = response.data.clients
      setClients(clientsFromApi)
    }
    loadClients()
  }, [])

  return (
    <ContainerBox>
      <Flex mb="8" justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Clientes
        </Heading>
        <ResponsiveButton
          as={Link}
          to="/clients/create"
          text="Criar novo"
          isWideVersion={isWideVersion ?? true}
          fontSize="sm"
          size="sm"
          colorScheme="orange"
          icon={RiAddLine}
          iconSize={20}
        />
      </Flex>
      <Stack spacing="6">
        {!!clients &&
          clients.map(client => (
            <Box w="100%" key={client.id}>
              <Box
                mx="auto"
                px={8}
                py={4}
                rounded="lg"
                shadow="lg"
                bg={bgBoxCard}
                w="100%"
                maxW="800px"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text as="span" fontSize="sm" color={colorTextResumo}>
                    Responsável Mirador:{' '}
                    <Text fontWeight="bold" as="span">
                      [user.name]
                    </Text>
                  </Text>
                  <HStack>
                    <Tag size="sm">Sample Tag</Tag>
                    <Tag size="sm">Sample Tag</Tag>
                    <Tag size="sm">Sample Tag</Tag>
                  </HStack>
                </Flex>

                <Box mt={2}>
                  <Text fontSize="2xl" fontWeight="700" mb="2">
                    {client.name}
                  </Text>
                  <Box color={colorTextResumo}>
                    <Text>3 solicitações em andamento</Text>
                    <Text>3 solicitações concluídas</Text>
                  </Box>
                </Box>

                <Flex justifyContent="space-between" alignItems="center" mt={4}>
                  <Button
                    as={Link}
                    to={`/clients/${client.id}/details`}
                    colorScheme="blue"
                    leftIcon={<Icon as={RiSearchEyeLine} fontSize={20} />}
                  >
                    Ver Detalhes
                  </Button>
                  <Button
                    as={Link}
                    to={`/clients/${client.id}/edit`}
                    colorScheme="teal"
                    leftIcon={<Icon as={RiPencilLine} fontSize={20} />}
                  >
                    Editar
                  </Button>
                </Flex>
              </Box>
            </Box>
          ))}
      </Stack>
      <Pagination />
    </ContainerBox>
  )
}

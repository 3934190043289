import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraSelectProps {
  name: string
  options: { value: string; text: string }[]
  label?: string
  error?: FieldError
  placeholder?: string
  onChage?(e: any): void
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, InputProps> = (
  { name, label, placeholder, error = null, options, ...rest },
  ref,
) => {
  const bgChakraSelect = useColorModeValue('whiteAlpha.900', 'gray.900')
  const focusBorderChakraSelect = useColorModeValue('orange.500', 'orange.400')
  const focusBgColorChakraSelect = useColorModeValue('gray.400', 'gray.200')
  const focusColorChakraSelect = useColorModeValue('white', 'gray.800')
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}> {label}</FormLabel>}
      <ChakraSelect
        name={name}
        placeholder={placeholder}
        id={name}
        focusBorderColor={focusBorderChakraSelect}
        bgColor={bgChakraSelect}
        _focus={{ bgColor: focusBgColorChakraSelect, color: focusColorChakraSelect }}
        variant="filled"
        _hover={{
          bgColor: bgChakraSelect,
        }}
        size="lg"
        {...rest}
        ref={ref}
        resize="none"
        onChange={rest.onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </ChakraSelect>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)

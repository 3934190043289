import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  useToast,
  VStack,
  useColorModeValue,
  Box,
} from '@chakra-ui/react'
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Link, useHistory } from 'react-router-dom'
import { RiAddLine, RiCheckboxCircleFill, RiDeleteBinLine } from 'react-icons/ri'
import { useEffect } from 'react'
import { AxiosError } from 'axios'
import { TextArea } from '../../components/Form/TextArea'
import { Input } from '../../components/Form/Input'
import api from '../../services/api'
import { ContainerBox } from '../../components/ContainerBox'
import { AppError } from '../../config/AppErrorType'
import { isOfType } from '../../utils/isOfType'
import { CreationPageHeader } from '../../components/CreationPageHeader'
import formatValue from '../../utils/formatValue'
import { Toast } from '../../components/Toast'

type SectionModelItem = {
  description: string
  isRequired: boolean
  fileModel: FileList
  itemPosition: number
}

type SectionModelsCreateFormData = {
  title: string
  items: SectionModelItem[]
}

type SectionModelItemProps = {
  id: string
  description: string
  isRequired: boolean
  sectionModelId: string
  createdAt: Date
}
type SectionModelProps = {
  id: string
  title: string
  createdAt: Date
  sectionModelItems: SectionModelItemProps[]
}

const sectionModelsCreateFormSchema = yup.object().shape({
  title: yup.string().required('Título é obrigatório'),
  items: yup.array().of(
    yup.object().shape({
      description: yup.string().required('A descrição da solicitação é obrigatória'),
      isRequired: yup.boolean(),
    }),
  ),
})

export default function SectionModelsCreate() {
  const borderColorBox = useColorModeValue('gray.200', 'gray.700')
  const colorCounterText = useColorModeValue('gray.200', 'gray.700')

  const history = useHistory()
  const toast = useToast()

  const { register, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(sectionModelsCreateFormSchema),
  })
  const { errors } = formState

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const handleCreateModel: SubmitHandler<SectionModelsCreateFormData> = async ({
    title,
    items,
  }) => {
    try {
      toast({
        render: () => (
          <Toast
            title="Creating section model"
            description="Criação em andamento..."
            status="info"
          />
        ),
        duration: 2000,
        isClosable: false,
        position: 'bottom-left',
      })

      const response = await api.post('/sections/models', { title })
      const { sectionModel: createdSectionModel } = response.data

      toast({
        render: () => (
          <Toast
            title="Saving items"
            description="salvando itens do modelo..."
            status="info"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      await Promise.all(
        items.map(async item => {
          const postItem = new FormData()
          postItem.append('fileModel', item.fileModel[0])
          postItem.append('isRequired', item.isRequired ? 'true' : 'false')
          postItem.append('description', item.description)
          postItem.append('itemPosition', item.itemPosition.toString())

          await api.post(`/sections/models/${createdSectionModel.id}/items`, postItem)

          // toast({
          //   render: () => (
          //     <Toast
          //       title="Item saved"
          //       description={`Item #${formatValue(index + 1)} saved successfully`}
          //       status="success"
          //     />

          //   ),
          //   duration: 3000,
          //   isClosable: false,
          //   position: 'bottom-left',
          // })
        }),
      )

      toast({
        render: () => (
          <Toast
            title="Section model created"
            description="Section model created successfully"
            status="success"
          />
        ),
        duration: 3000,
        isClosable: false,
        position: 'bottom-left',
      })

      history.push('/solicitations/section-models')
    } catch (err) {
      const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
        ? err.response?.data.message
        : err.message || 'Erro ao criar modelo'

      toast({
        render: () => (
          <Toast
            title="Section model creation error"
            description={errorMessage}
            status="error"
          />
        ),
        duration: 3500,
        isClosable: false,
        position: 'bottom-left',
      })
    }
  }

  useEffect(() => {
    if (fields.length === 0) {
      append({})
    }
  }, [append, fields.length])

  return (
    <ContainerBox as="form" onSubmit={handleSubmit(handleCreateModel)}>
      <CreationPageHeader title="Criar modelo de sessão" />

      <VStack spacing="8">
        <Input error={errors.title} {...register('title')} label="Título da sessão" />

        {fields.map(({ id }, index) => (
          <Box
            px="6"
            py="4"
            w="100%"
            borderColor={borderColorBox}
            borderWidth="1px"
            borderRadius="8px"
            key={id}
          >
            <Flex w="100%" align="center" justify="space-between">
              <Text textAlign="right" fontWeight="bold" color={colorCounterText}>
                #{formatValue(index + 1)}
              </Text>
              <IconButton
                size="sm"
                disabled={fields.length === 1}
                colorScheme="red"
                variant="link"
                icon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                aria-label="Deletar item"
                onClick={() => remove(index)}
              />
            </Flex>
            <VStack spacing="6" w="100%" mt="2">
              <TextArea
                error={
                  errors.items !== undefined ? errors.items[index]?.description : null
                }
                {...register(`items[${index}].description`)}
                label="Descrição da solicitação"
              />
              <input
                type="hidden"
                {...register(`items[${index}].itemPosition`, { value: index })}
              />
              <SimpleGrid minChildWidth="240px" spacing={['6', '8']} w="100%">
                <FormControl
                  isInvalid={
                    errors.items !== undefined && errors.items[index]?.isRequired
                  }
                >
                  <FormLabel htmlFor="isRequired"> Campo obrigatório</FormLabel>
                  <Checkbox
                    colorScheme="orange"
                    {...register(`items[${index}].isRequired`)}
                  >
                    É obrigatório
                  </Checkbox>

                  <FormErrorMessage>
                    {errors.items !== undefined
                      ? /* errors.items[index]?.type?.message */ ''
                      : null}
                  </FormErrorMessage>
                </FormControl>
                <Input
                  type="file"
                  error={
                    errors.items !== undefined ? errors.items[index]?.fileModel : null
                  }
                  {...register(`items[${index}].fileModel`)}
                  label="Arquivo Modelo"
                />
              </SimpleGrid>
            </VStack>
          </Box>
        ))}
      </VStack>

      <Flex mt={['6', '8']} justify="space-between">
        <Button
          colorScheme="purple"
          leftIcon={<Icon as={RiAddLine} fontSize="20" />}
          onClick={() => append({})}
        >
          Novo item
        </Button>

        <HStack spacing="4">
          <Button
            as={Link}
            to="/solicitations/section-models"
            colorScheme={useColorModeValue('blackAlpha', 'whiteAlpha')}
          >
            Cancelar
          </Button>
          <Button isLoading={formState.isSubmitting} colorScheme="orange" type="submit">
            Salvar
          </Button>
        </HStack>
      </Flex>
    </ContainerBox>
  )
}

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ContainerBox } from '../../components/ContainerBox'
import { Pagination } from '../../components/Pagination'
import { ResponsiveButton } from '../../components/ResponsiveButton'
import api from '../../services/api'

type UserProps = {
  id: string
  name: string
  email: string
  createdAt: Date
  avatar: string
}

export default function Users() {
  const tableColorScheme = useColorModeValue('blackAlpha', 'whiteAlfa')

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })

  const [users, setUsers] = useState<UserProps[]>([])

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get<{ users: UserProps[] }>('/users')
      const usersFromApi = response.data.users
      setUsers(usersFromApi)
    }
    loadUsers()
  }, [])

  return (
    <ContainerBox>
      <Flex mb="8" justify="space-between" align="center">
        <Heading size="lg" fontWeight="normal">
          Usuários
        </Heading>
        <ResponsiveButton
          as={Link}
          to="/users/create"
          text="Criar novo"
          isWideVersion={isWideVersion ?? true}
          fontSize="sm"
          size="sm"
          colorScheme="orange"
          icon={RiAddLine}
          iconSize={20}
        />
      </Flex>
      <Table colorScheme={tableColorScheme}>
        <Thead>
          <Tr>
            <Th px={['4', '4', '6']} color="gray.300" width="8">
              <Checkbox colorScheme="orange" />
            </Th>
            <Th>Usuário</Th>
            {isWideVersion && <Th>Data de cadastro</Th>}
            <Th width="8" />
          </Tr>
        </Thead>
        <Tbody>
          {users.map(user => (
            <Tr key={user.id}>
              <Td px={['4', '4', '6']}>
                <Checkbox colorScheme="orange" />
              </Td>
              <Td>
                <Flex align="center" justify="start">
                  <Avatar size="sm" name={user.name} src={user.avatar} mr="4" />
                  <Box>
                    <Text fontWeight="bold">{user.name}</Text>
                    <Text fontSize="sm">{user.email}</Text>
                  </Box>
                </Flex>
              </Td>
              {isWideVersion && <Td>{format(new Date(user.createdAt), 'dd/MM/yyy')}</Td>}
              <Td>
                <ResponsiveButton
                  as="a"
                  text="Editar"
                  isWideVersion={isWideVersion ?? true}
                  fontSize="sm"
                  size="sm"
                  colorScheme="teal"
                  icon={RiPencilLine}
                  iconSize={16}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination />
    </ContainerBox>
  )
}

import { Button, Flex, HStack, useToast, VStack } from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { Link, useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Input } from '../../components/Form/Input'
import api from '../../services/api'
import { isOfType } from '../../utils/isOfType'
import { AppError } from '../../config/AppErrorType'
import { ContainerBox } from '../../components/ContainerBox'
import { CreationPageHeader } from '../../components/CreationPageHeader'
import { Toast } from '../../components/Toast'

type ClientCategoriesCreateFormData = {
  name: string
}

type ClientCategoryProps = {
  id: string
  name: string
}

const clientCategoriesCreateFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
})

export default function ClientCategoriesCreate() {
  const history = useHistory()

  const toast = useToast()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(clientCategoriesCreateFormSchema),
  })
  const { errors } = formState

  const handleCreateClientCategory: SubmitHandler<ClientCategoriesCreateFormData> =
    async data => {
      try {
        await api.post<{ clientCategory: ClientCategoryProps }>(
          '/clients/categories',
          data,
        )

        toast({
          render: () => (
            <Toast
              title="Client category created"
              description="Client category created successfully"
              status="success"
            />
          ),
          duration: 3000,
          isClosable: false,
          position: 'bottom-left',
        })
        history.push('/clients/categories')
      } catch (err) {
        const errorMessage = isOfType<AxiosError<AppError>>(err, 'response')
          ? err.response?.data.message
          : err.message || 'Erro ao criar categoria de cliente'

        toast({
          render: () => (
            <Toast
              title="Client category creation error"
              description={errorMessage}
              status="error"
            />
          ),
          duration: 3500,
          isClosable: false,
          position: 'bottom-left',
        })
      }
    }

  return (
    <ContainerBox as="form" onSubmit={handleSubmit(handleCreateClientCategory)}>
      <CreationPageHeader title="Criar categoria de cliente" />
      <VStack spacing="8">
        <Input error={errors.name} {...register('name')} label="Nome" />
      </VStack>
      <Flex mt={['6', '8']} justify="flex-end">
        <HStack spacing="4">
          <Button as={Link} to="/users" colorScheme="gray">
            Cancelar
          </Button>
          <Button isLoading={formState.isSubmitting} colorScheme="orange" type="submit">
            Salvar
          </Button>
        </HStack>
      </Flex>
    </ContainerBox>
  )
}

import { Image } from '@chakra-ui/react'
import logoMirador from '../../assets/mirador.svg'

export function Logo() {
  return (
    <>
      <Image src={logoMirador} width={['28', '40', '48', '56', '60']} />
      {/* <Text fontSize={["2xl","3xl"]} fontWeight="bold" letterSpacing="tight" w="64">
      DashGO
      <Text as="span" ml={1} color="orange.500">
        .
      </Text>
    </Text> */}
    </>
  )
}

import { Stack } from '@chakra-ui/react'
import {
  RiContactsLine,
  RiDashboardLine,
  RiTeamLine,
  RiInputMethodLine,
} from 'react-icons/ri'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

export function SidebarNav() {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="Geral">
        <NavLink href="/dashboard" icon={RiDashboardLine}>
          Dashboard
        </NavLink>
        <NavLink href="/users" icon={RiTeamLine}>
          Usuários
        </NavLink>
      </NavSection>

      <NavSection title="Solicitações">
        <NavLink href="/solicitations/section-models" icon={RiInputMethodLine}>
          Modelos de sessão
        </NavLink>
      </NavSection>

      <NavSection title="Clientes">
        <NavLink href="/clients/categories" icon={RiContactsLine}>
          Categorias
        </NavLink>
        <NavLink href="/clients" icon={RiContactsLine}>
          Listagem de clientes
        </NavLink>
      </NavSection>
    </Stack>
  )
}

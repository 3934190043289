import { Text, Avatar, Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { useAuth } from '../../hooks/auth'

interface ProfileProps {
  showProfileData?: boolean
}
export function Profile({ showProfileData = true }: ProfileProps) {
  const colorTextEmail = useColorModeValue('gray.500', 'gray.300')
  const { user } = useAuth()

  return (
    <Flex align="center">
      {showProfileData && (
        <Box mr="4" textAlign="right">
          <Text>{user.name}</Text>
          <Text color={colorTextEmail} fontSize="small">
            {user.email}
          </Text>
        </Box>
      )}

      <Avatar size="md" name={user.name} src={user.avatar} />
    </Flex>
  )
}
